import { nanoid } from 'nanoid';

// HEAD DATA
export const headData = {
  title: 'My Back Hertz', // e.g: 'Name | Developer'
  lang: 'en', // e.g: en, es, fr, jp
  description: 'Audio recording and post production for multimedia content', // e.g: Welcome to my website
  url: 'https://www.backhz.com',
  keywords: [
    'audio',
    'sound',
    'recording',
    'mixing',
    'mastering',
    'multimedia',
    'post production',
    'audio engineering',
    'podcast',
    'podcasting',
    'podcast production',
    'RTF',
    'radio',
    'television',
    'film',
    'foley',
    'adr',
    'pro tools',
    'avid',
    'logic',
    'reason',
    'sound design',
  ],
};

// HERO DATA
export const heroData = {
  title: '',
  name: 'Back Hz',
  subtitle: 'audio engineering',
  cta: 'Learn More',
};

// ABOUT DATA
export const aboutData = {
  img: 'valueofday_tall.jpg',
  imgTablet: 'valueofday.jpg',
  paragraphOne: "I provide audio post production -- editing, mixing, sound design, voice-over, overdubs, and more -- to content producers of all types. Whether podcasting, digital marketing, creating videos for work or play, or any of the other many reasons you might suddenly find yourself looking for audio assistance in this remote & digital world, look no further. I'm independent, have my own professional equipment & am ready to help make audio a non-issue, so you can focus on the bigger picture.",
  paragraphTwo: 'The remote world of today has more content being generated than ever. Maybe you\'re launching a podcast, or creating video presentations for work, or you have ideas & dream of producing polished advertisements & social media content for your small business.',
  paragraphThree: 'Are you having difficulty with noisy interviews recorded over video chat? Overwhelmed because you are spending too much time trying to get your mic to sound good, that it\'s cutting into your actual work responsibilities? Mystified by sample rates, bit depths, time code and file formats? Do you dream of producing content which integrates features such as theme music and sound design, professional voice-over, and remote interviews, yet find frustration making it all happen efficiently, within your budget, and to a professional and consistent broadcast standard?',
  paragraphFour: 'Back Hz Sound is the answer to your needs. I am an audio engineer with many years of live and studio experience. I can work remotely and flexibly to edit, mix and help produce your content.',
  resume: '', // if no resume, the button will not show up
};

// PROJECTS DATA
export const projectsData = [
  {
    id: nanoid(),
    img: 'project.jpg',
    title: 'The Emotion Motion Podcast',
    info: 'A podcast for children and their parents to listen to together and explore Social Emotional Learning through interactive storytelling. I leveraged multiple sources of prerecorded audio from the client & sourced and created sound effects in order to execute the client\'s vision. Post production and audio consultancy provided by Back Hz Sound for Move This World Audio Network.',
    info2: '',
    url: 'https://open.spotify.com/embed-podcast/show/4Iw7EvKN19vLptvNl3f2KM',
    repo: '', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'project.jpg',
    title: 'Saracast: Conversations in Social Emotional Learning',
    info: 'The Saracast is the brainchild of Move This World CEO Sara Potler LaHayne, and includes remote interviews with prominent figures in Social Emotional Learning. Demonstrates a collaboration between the client and Back Hz to maximize, and make consistent, the quality of audio recorded from challenging sources. Helped the client develop production techniques to improve results as the season went on.',
    info2: '',
    url: 'https://open.spotify.com/embed-podcast/show/5AYtc16LAxCEByTnNwXYVI',
    repo: '', // if no repo, the button will not show up
  },
];

// CONTACT DATA
export const contactData = {
  cta: '',
  btn: '',
  email: 'aaron@backhz.com',
};

// FOOTER DATA
export const footerData = {
  networks: [
    {
      id: nanoid(),
      name: 'instagram',
      url: 'https://www.instagram.com/backhz',
    },
    {
      id: nanoid(),
      name: 'facebook',
      url: 'https://www.facebook.com/backhzsound',
    },
    {
      id: nanoid(),
      name: 'linkedin',
      url: 'https://www.linkedin.com/in/aaron-altounian',
    },
  ],
};

// Github start/fork buttons
export const githubButtons = {
  isEnabled: false, // set to false to disable the GitHub stars/fork buttons
};
